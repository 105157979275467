var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lastBox",staticStyle:{"background-color":"#f8fcff"}},[_vm._m(0),_vm._m(1),_c('el-card',{staticClass:"box-card",staticStyle:{"width":"62.5%","margin":"20px auto"},attrs:{"body-style":{ padding: '0' }}},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v("高排放老旧车提前淘汰补助及奖励标准")]),_c('img',{attrs:{"src":require("../../../public/img/table.png")}}),_c('div',{staticClass:"last"},[_c('p',{staticClass:"p1"},[_vm._v(" 注：对于在2019年3月31日前主动淘汰车辆的车主，按2018年度补助限额标准的5%进行奖励； 对于在2019年6月30日、9月30日、12月31日前主动淘汰车辆的车主，分别按照2019年度补助 限额标准的4%、3%、2%进行奖励；补助至2020年12月31日截止。 ")]),_c('p',{staticClass:"p2"},[_c('span',[_vm._v("相关链接：")]),_c('span',{staticClass:"relatedLink",on:{"click":function($event){return _vm.linkToResolve(
                'http://www.shaanxi.gov.cn/zfxxgk/fdzdgknr/zcwj/szfbgtwj/szbf/201810/t20181011_1666856.html'
              )}}},[_vm._v("陕西省人民政府办公厅关于印发高排放老旧机动车淘汰更新实施计划（2018-2020 年）的通知，")]),_c('br'),_c('span',{staticClass:"relatedLink",on:{"click":function($event){return _vm.linkToResolve(
                'http://www.xa.gov.cn/gk/zcfg/zcjd/zjjd/5d49197afd850833ac5b50d3.html'
              )}}},[_vm._v("高排放老旧机动车淘汰越早补助越高，最高可领33000元。")])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"bg",attrs:{"src":require("../../../public/img/homeNavBg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rightImg",staticStyle:{"position":"absolute","right":"1%","top":"125%"}},[_c('div',{staticStyle:{"width":"60px","height":"60px","display":"flex","align-items":"center","justify-content":"center","text-align":"center","background":"#ffffff","border-radius":"5px","box-shadow":"0px 0px 25px 0px rgba(32, 90, 196, 0.14)"}},[_c('img',{staticStyle:{"width":"33px","height":"36px"},attrs:{"src":"/img/right2.png","alt":""}})]),_c('div',{staticStyle:{"width":"60px","height":"60px","display":"flex","align-items":"center","justify-content":"center","text-align":"center","background":"#ffffff","border-radius":"5px","box-shadow":"0px 0px 25px 0px rgba(32, 90, 196, 0.14)","margin-top":"10px"}},[_c('img',{staticStyle:{"width":"33px","height":"36px"},attrs:{"src":"/img/right1.png","alt":""}})])])
}]

export { render, staticRenderFns }