<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner">
      <img class="bg" src="../../../public/img/homeNavBg.png" />
    </div>
    <div class="rightImg" style="position: absolute;right: 1%;top: 125%;">
      <div
        style="width: 60px;height: 60px;display:flex;align-items:center;justify-content:center;text-align:center;background: #ffffff;border-radius: 5px;box-shadow: 0px 0px 25px 0px rgba(32, 90, 196, 0.14);">
        <img style="width: 33px;height: 36px;" src="/img/right2.png" alt="" />
      </div>
      <div
        style="width: 60px;height: 60px;display:flex;align-items:center;justify-content:center;text-align:center;background: #ffffff;border-radius: 5px;box-shadow: 0px 0px 25px 0px rgba(32, 90, 196, 0.14);margin-top: 10px;">
        <img style="width: 33px;height: 36px;" src="/img/right1.png" alt="" />
      </div>
    </div>
    <el-card :body-style="{ padding: '0' }" style="width:62.5%;margin: 20px auto;" class="box-card">
      <div class="content">
        <p class="title">高排放老旧车提前淘汰补助及奖励标准</p>
        <img src="../../../public/img/table.png" />
        <div class="last">
          <p class="p1">
            注：对于在2019年3月31日前主动淘汰车辆的车主，按2018年度补助限额标准的5%进行奖励；
            对于在2019年6月30日、9月30日、12月31日前主动淘汰车辆的车主，分别按照2019年度补助
            限额标准的4%、3%、2%进行奖励；补助至2020年12月31日截止。
          </p>
          <p class="p2">
            <span>相关链接：</span>
            <span class="relatedLink" @click="
                linkToResolve(
                  'http://www.shaanxi.gov.cn/zfxxgk/fdzdgknr/zcwj/szfbgtwj/szbf/201810/t20181011_1666856.html'
                )
              ">陕西省人民政府办公厅关于印发高排放老旧机动车淘汰更新实施计划（2018-2020
              年）的通知，</span><br />
            <span class="relatedLink" @click="
                linkToResolve(
                  'http://www.xa.gov.cn/gk/zcfg/zcjd/zjjd/5d49197afd850833ac5b50d3.html'
                )
              ">高排放老旧机动车淘汰越早补助越高，最高可领33000元。</span>
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "adressMaintain",
  data() {
    return {
      form: {
        name: "",
        type: [],
        desc: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    linkToResolve(path) {
      window.open(path, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  .bg {
    height: 580px;
    width: 100%;
    object-fit: cover;
  }
}
.content {
  text-align: center;
  padding: 49px 0 50px 0;
  .title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }
  img {
    width: 743px;
    height: 641px;
    display: block;
    margin: 0 auto;
  }
  .last {
    width: 700px;
    height: 138px;
    border: solid 1px #d4d4d4;
    margin: 0 auto;
    padding: 21px;
    border-top: none;
    p {
      line-height: 28px;
      text-align: left;
      color: #666666;
    }
    .p2 {
      span {
        color: #666666;
      }
      .relatedLink {
        cursor: pointer;
        color: #24a2aa;
      }
    }
  }
}
</style>
